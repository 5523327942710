import React from "react";
import Navbar from "./../components/Navbar/Navbar";
import CoverPage from "../components/Home/coverPage/coverPage";
import Luxury from "./../components/Home/Luxury/luxury";
import Premium from "./../components/Home/Premium/premium";
import Standard from "./../components/Home/Standard/standard";
import Footer from "./../components/Footer/footer";
import "./../App.css";
import { useState, useEffect } from "react";
import Offlinepage from "../components/offline/Offlinepage";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Homepage() {
  const { t } = useTranslation();
  const [homeLoading, setHomeloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setHomeloading(false);
    }, 3000);
  }, []);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  console.log(isOnline);

  useEffect(() => {
    const handleOffline = () => {
      setIsOnline(false);
      toast.error(t("Offline"), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    const handleOnline = () => {
      setIsOnline(true);
      toast.success(t("Online"), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isOnline ? (
        /* Online content */
        <div className="home">
          <Navbar />
          <CoverPage />
          <Luxury />
          <Premium />
          <Standard />
          <Footer />
        </div>
      ) : (
        /* Display the offline page */
        <Offlinepage />
      )}
    </div>
  );
}

export default Homepage;
