import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "./ServicesDash.css";
import axios from "axios";
import AllServices from "./AllServices";
import AddServiceForm from "./AddServiceForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

export default function ServicesDash() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const [User, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `https://cityflat.onrender.com/user/${User.id}`
        );
        const user = response.data;
        setIsAdmin(user.role === "ADMIN");
      } catch (error) {
        console.error(error);
      }
    }

    if (User) {
      getUser();
    }
  }, [User]);
  const handleSubmit = async ({ name, description, pricePerNight }) => {
    try {
      const formServiceData = {
        name,
        description,
        pricePerNight,
      };

      const res = await axios.post(
        "https://cityflat.onrender.com/user/services/addService",
        formServiceData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.error(err); // Handle error here
    }
  };

  const [show, setShow] = useState("all");

  return (
    <>
      {isAdmin ? (
        <>
          <Sidebar />
          <div className="services-dash-cont bg-dark text-light">
            <h1 className="text-light text-center">Dienstleistungen-Dashboard</h1>
            <ul className="services-dash-nav d-flex">
              <li
                onClick={() => {
                  setShow("all");
                }}
                className=""
              >
                <button className="btn btn-primary me-3">Alle Dienstleistungen</button>
              </li>
              <li
                onClick={() => {
                  setShow("add");
                }}
              >
                <button className="btn btn-success"><FontAwesomeIcon icon={faAdd}/> Hinzufügen</button>
              </li>
            </ul>
            {show === "all" ? (
              <AllServices />
            ) : (
              <AddServiceForm onSubmit={handleSubmit} />
            )}
          </div>
        </>
      ) : (
        <div className="div-denied d-flex align-items-center justify-content-center">
          <h1 className="text-center display-1">
            ⚠️ 404 NOT FOUND
          </h1>
        </div>
      )}
    </>
  );
}
