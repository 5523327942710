import "./Loader.css";

const Loader = ({ className }) => {
  return (
    <div className={`loading-cont ${className}`}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
